<template>
    <div id="header">
        <TitleText />
        <TitleCanvas />
        <div id="noise"></div>
    </div>
</template>

<script>
    import TitleCanvas from "@/components/TitleCanvas";
    import TitleText from "@/components/TitleText";

    export default {
        name: "TitlePage",
        components: {TitleText, TitleCanvas},
        computed: {
            noise () {
                return require('@/assets/noise.png')
            }
        },
    }
</script>

<style scoped>
    #header {
        position: relative;
        background-color: #485461;
        background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
        overflow:hidden;
        width: 100%;
        height: 100vh;
    }
    #noise {
        position: absolute;
        width: 300%;
        height: 400%;
        top:-200%;
        left: -50%;
        background-image: url('~@/assets/noise.png');
        background-size: auto;
        background-repeat: repeat;
        mix-blend-mode: overlay;
        opacity: .15;
        animation: grain 5s steps(10) infinite;
    }
    @keyframes mymove {
        from {background-position-x: 0px;}
        to {background-position-x: 400px;}
    }
    @keyframes grain{0%,100%{transform:translate(0,0)}10%{transform:translate(-5%,-10%)}20%{transform:translate(-15%,5%)}30%{transform:translate(7%,-25%)}40%{transform:translate(-5%,25%)}50%{transform:translate(-15%,10%)}60%{transform:translate(15%,0%)}70%{transform:translate(0%,15%)}80%{transform:translate(3%,35%)}90%{transform:translate(-10%,10%)}}
</style>