import { createApp } from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './assets/font.css';

library.add(faGithub, faLinkedin);


const app = createApp(App);
// Add fontawesomeicon component
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
