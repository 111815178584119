<template>
    <div id="background"></div>
</template>

<script>
    import $ from 'jquery'
    import {
        PerspectiveCamera,
        Scene,DirectionalLight,MeshBasicMaterial,Mesh,
        WebGLRenderer,
    } from 'three';
    import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'

    export default {
        name: "TitleCanvas",
        data: () => ({
            width: 0,
            height: 0,
        }),
        beforeMount() {
            this.resizeCanvas();
        },
        mounted() {
            // Get a reference to the container element that will hold our scene
            const container = document.querySelector('#background');

            // create a Scene
            const scene = new Scene();

            let obj1, obj2, obj3;
            // Create a camera
            const fov = 50; // AKA Field of View
            const aspect = container.clientWidth / container.clientHeight;
            const near = 0.1; // the near clipping plane
            const far = 100; // the far clipping plane

            const camera = new PerspectiveCamera(fov, aspect, near, far);

            let light = new DirectionalLight(0xfffffff, 0.5);
            light.castShadow = true;
            scene.add(light);

            // every object is initially created at ( 0, 0, 0 )
            // move the camera back so we can view the scene
            camera.position.set(0, -0.2, 1.5);


            // create a geometry
            const loader = new GLTFLoader();

            loader.load('weibull.gltf',
                function ( gltf ) {
                    const geometry = gltf.scene.children[0].geometry;
                    let material = new MeshBasicMaterial( {color: 0x966ea4} );
                    let cube = new Mesh( geometry, material );
                    obj1 = cube;
                    obj1.position.y = -0.2;
                    obj1.rotation.z = 0.4;
                    scene.add(obj1);


                    material = new MeshBasicMaterial( {color: 0x848c85} );
                    cube = new Mesh( geometry, material );
                    obj2 = cube;
                    obj2.position.y = -0;
                    obj2.rotation.z = 0.4;
                    scene.add(obj2);

                    material = new MeshBasicMaterial( {color: 0x7c7c8c} );
                    cube = new Mesh( geometry, material );
                    obj3 = cube;
                    obj3.position.y = -0;
                    obj3.rotation.z = 0.4;
                    scene.add(obj3);
                });

            // create the renderer
            const renderer = new WebGLRenderer({ alpha: true });
            renderer.setClearColor( 0x000000, 0 );
            renderer.domElement.id = 'background-canvas';

            // next, set the renderer to the same size as our container element
            renderer.setSize(container.clientWidth, container.clientHeight);

            // finally, set the pixel ratio so that our scene will look good on HiDPI displays
            renderer.setPixelRatio(window.devicePixelRatio);

            // add the automatically created <canvas> element to the page
            container.append(renderer.domElement);

            // render, or 'create a still image', of the scene
            function animate() {
                requestAnimationFrame( animate );
                if(obj1) {
                    obj1.rotation.z -= 0.005;
                    obj1.rotation.y += 0.008;
                }
                if(obj2) {
                    obj2.rotation.x += 0.003;
                    obj2.rotation.z += 0.009;
                }
                if(obj3) {
                    obj3.rotation.x -= 0.001;
                    obj3.rotation.y -= 0.002;
                }
                renderer.render( scene, camera );
            }
            animate();


        },
        created() {
            window.addEventListener("resize", this.resizeCanvas);
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeCanvas);
        },
        methods: {
            resizeCanvas() {
                this.width = $(window).outerWidth();
                this.height = $(window).outerHeight();
            }
        }
    }
</script>

<style scoped>
    #background {
        width: 100%;
        height: 100vh;
    }
</style>
<style>
    canvas {
        filter: blur(100px);
        opacity: 0.5;
    }
</style>