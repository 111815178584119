<template>
  <div id="app" :class="!show && 'hidden'">
    <TitlePage />
  </div>
</template>

<script>
import TitlePage from "@/pages/TitlePage";

export default {
  name: 'App',
  components: {
    TitlePage
  },
  data: () => ({
    show: false,
    page: "title",
  }),
  mounted() {
    this.show = true;
  }
}
</script>

<style>
html, body {
  background-color: #485461;
  margin: 0;
  color: #f2f2f2;
}
a {
  color: #f2f2f2;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity 0.6s ease-in-out;
}
.hidden {
  opacity: 0;
}
</style>
