<template>
    <div id="title_text_wrapper">
        <div id="text_wrapper" class="has-text-centered">
            <h1 id="title_1" class="main_title">Thinsz</h1>
            <!--
            <p>
                <a href="https://github.com/davidthinsz/">
                <span class="icon">
                    <font-awesome-icon :icon="['fab', 'github']" size="2x" />
                </span>
                </a>
                <a href="https://www.linkedin.com/in/davidthinsz/">
                <span class="icon">
                    <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
                </span>
                </a>
            </p>
            -->
        </div>
    </div>
</template>

<script>
    export default {
        name: "TitleText"
    }
</script>

<style scoped>


#title_text_wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    pointer-events: none;
    mix-blend-mode: exclusion;
    z-index: 9999;
    font-family: Gilroy, Arial, sans-serif;
}
.main_title {
    font-size: 14rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 10px rgba(0,0,0,0.8);
    font-weight: 800;
}
#text_wrapper {
    margin-top: -300px;
}
#title_1 {
    margin-bottom: -60px;
}
.icon {
    margin: 0 0.4em;
}
a {
    pointer-events:  auto;
}
</style>